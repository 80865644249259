@use '~/ui/styles/breakpoints.module';

.dl {
  margin-block: 48px;

  @media (min-width: breakpoints.$lg) {
    margin-block: 64px;
  }
}

.faq {
  & + &:not([hidden]) {
    margin-top: 40px;
  }

  &[hidden] {
    display: none;
  }
}

.button {
  width: max-content;
}
