@import '/styles/base.scss';

.contactSection {
  align-items: flex-start;
}

.contactTitle {
  margin-bottom: 3.2rem;

  @include md {
    margin-bottom: 2.4rem;
  }
}

.contactItem {
  margin-top: 3.2rem;
  & > div {
    padding-right: 2.4rem;
  }
}

.icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.4rem;
}

.phone {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 1.6rem 0 0;
}

.role {
  color: $color-caption;
}

.children {
  @include sm-only {
    margin-top: 8.8rem;
  }
}
