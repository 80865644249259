@use '/styles/base.scss' as *;

.previewVideo {
  position: relative;
  overflow: hidden;
  flex: 1;
  width: 100vw;

  iframe {
    pointer-events: none;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@include md {
  @media (orientation: portrait) {
    .previewVideo {
      height: 100%;
    }
  }
}

@include lg {
  .previewVideo {
    height: 100%;
  }
}

.videoWrapper {
  position: relative;

  aspect-ratio: 16/9;
  width: 100%;
  height: 100%;

  object-fit: cover;

  iframe {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  &:not(.previewVideo) {
    width: 100%;
    max-height: 100%;
  }
}

.videoWrapperInAccordion {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  width: unset;
  height: 100%;
}
