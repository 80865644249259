@import '/styles/base';
@import '../navigation.constants.module';

$tranistion-duration: 0.8s;

.navigation {
  @include lg {
    position: sticky;
    z-index: 3;

    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-sizing: border-box;
    width: var(--nav-width);
    height: calc(100 * var(--vh));

    background-color: var(--theme-primary);
  }

  pointer-events: all;

  position: absolute;
  z-index: 2;
  top: 0;

  width: 100%;
  height: fit-content;
}

.contentTop {
  @include md {
    padding: 3.2rem 4.8rem;
  }

  @include lg {
    display: block;

    height: auto;
    margin-block: var(--nav-wordmark-spacing);
    padding: 0 4rem;

    transition: none;

    .navigationLarge & {
      padding: 6.4rem 13.6rem 0 4rem;
    }
  }

  position: relative;
  z-index: 1;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding: 1.6rem 2rem 2.4rem;

  background-color: var(--theme-primary);

  transition: padding 0.9s $ease-in-out-quart;
}

.contentTopScrolled {
  @include md {
    padding: 2.4rem 4.8rem;
  }

  @include lg {
    padding: $primary-top-padding-lg 4rem 0;
  }

  padding-bottom: 1.6rem;
}

.contentTopInnerWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  opacity: 1;

  transition: opacity 0.8s;

  .contentTopHidden & {
    @include lg {
      opacity: 1;
    }

    opacity: 0;
  }
}

.wordmarkLogo {
  @include focus;

  display: flex;

  // On "Mini" iPhones (<250px), the wordmark logo causes the menu button to overflow. 60px is the width of the menu
  //   button. https://github.com/awmf/mellonorg-webapp/issues/1768
  max-width: calc(100% - 60px);
  padding: 0;

  &:hover {
    color: rgba(var(--theme-copy-rgb), 0.65);
  }

  svg {
    @include lg {
      height: var(--nav-wordmark-height);
      transition: none;
    }

    height: $wordmark-logo-height;
    transition: height 0.9s $ease-in-out-quart;
  }
}

.wordmarkLogoScrolled {
  svg {
    @include lg {
      height: 7.7rem;
      margin-bottom: 4rem;
    }

    height: 4rem;
  }
}

.contentBottom {
  @include md {
    transform: translateY(calc(-100% + $primary-sticky-tablet-height));
  }

  @include lg {
    position: relative;
    transform: none;

    justify-content: space-between;

    width: $primary-lg-width;
    height: auto;

    transition: none;
  }

  position: fixed;
  bottom: 0;
  transform: translateY(calc(-100% + $primary-sticky-mobile-height));

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;

  box-sizing: border-box;
  width: 100vw;
  height: 0;

  background: var(--theme-primary);

  transition:
    transform $tranistion-duration $ease-in-out-quart,
    height 0s $tranistion-duration;
}

.contentBottomExpanded {
  @include lg {
    transform: none;
  }

  transform: translateY(0%);
  height: calc(100 * var(--vh));
  transition:
    transform $tranistion-duration $ease-in-out-quint,
    height 0s 0s;
}

.contentBottomInstant,
.contentTopInstant .contentTopInnerWrapper {
  transition: none;
}

.contentBottomInnerWrapper {
  @include md {
    padding: 0 4.8rem 4.8rem;
  }

  @include lg {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    padding: 0 4rem 4rem;

    .navigationLarge & {
      padding: 0 13.6rem 4rem 4rem;
    }
  }

  overflow: auto;
  padding: 0 2rem 2rem;
}

.alternativeContent {
  @include lg {
    .showDesktopContent & {
      display: contents;
    }
  }

  display: none;
}

.defaultContent {
  @include lg {
    .showDesktopContent & {
      display: none;
    }
  }

  display: contents;
}

.navigationItemsWrapper {
  @include md {
    gap: 1.6rem;
    margin-bottom: 4rem;
  }

  @include lg {
    gap: 0.8rem;
    margin-bottom: 0;
  }

  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: flex-start;

  margin-bottom: 3.2rem;
}

.navigationItem {
  @include focus;
  @include sectionTitle;

  @include lg {
    @include bodyLarge;
  }

  display: inline-flex;
  align-items: center;

  padding: 0;

  color: var(--theme-copy);

  transition: color 0.3s ease;

  &:hover {
    cursor: pointer;
    color: rgba(var(--theme-copy-rgb), 0.65);
  }
}

.navigationItemActive {
  opacity: 0.6;
}

.icon {
  @include md {
    width: 3.2rem;
  }

  @include lg {
    width: 1.6rem;
  }

  width: 2.8rem;
  margin-top: 0.3rem;
  margin-left: 0.4rem;
}

.menuButton {
  @include focus(0);

  @include lg {
    display: none;
  }

  transform: translate(1.2rem, -1.2rem);

  display: inline-flex;

  box-sizing: content-box;
  height: 2rem;
  padding: 1.2rem;

  color: var(--theme-copy);

  transition: color 0.3s ease;

  &:hover {
    color: rgba(var(--theme-copy-rgb), 0.65);
  }

  &:hover {
    cursor: pointer;
  }
}

.closeIcon {
  width: 2rem;
}

.grantResourcesButton {
  @include md {
    width: auto;
  }

  @include lg {
    margin-top: 3rem;
  }

  width: 100%;
}
