@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/color.module';

.newsletter {
  padding: 2.4rem 1.6rem 3.2rem 2.4rem;
  color: color.$black;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
    var(--theme-primary);

  @media (min-width: breakpoints.$md) {
    padding: 3.2rem 3.2rem 4rem;
  }

  @media (min-width: breakpoints.$lg) {
    padding: 4rem 4.5rem 4.5rem 4rem;
  }
}

.newsletterFooter {
  color: var(--theme-copy);
}

.newsletterWithoutPadding {
  padding: 0;
}

.checkmarkIcon {
  width: 3.2rem;
  margin-bottom: 1.2rem;
}

.title {
  margin-bottom: 4.4rem;

  .newsletterFooter & {
    @media (min-width: breakpoints.$md) {
      padding-right: 35.7%;
    }

    @media (min-width: breakpoints.$lg) {
      padding-right: 15.2%;
    }
  }
}

.disclaimer {
  display: block;
  margin-top: 1.6rem;
}

.disclaimerLink {
  position: relative;
  text-decoration: underline;
  outline: none;

  &:focus-visible {
    border: 0.2rem solid color.$white;
    outline: 0.1rem solid color.$black;
  }

  &:focus-visible::before {
    display: none;
  }

  &:focus-visible::after {
    display: none;
  }
}
