@use '~/ui/styles/breakpoints.module';

.introParagraph {
  @media (min-width: breakpoints.$md) {
    padding-right: 40px;
  }
}

.graphsSection {
  padding-block: 48px;
  background-color: #fbf6f3;

  @media (min-width: breakpoints.$md) {
    padding-block: 64px;
  }

  @media (min-width: breakpoints.$lg) {
    padding-block: 88px;
  }
}
