@use '~/ui/styles/utils.module';

.headerButton {
  @include utils.focus;

  cursor: pointer;
  padding: 0;
}

.headerTextColumn {
  text-align: left;
}

.expand {
  transition: margin-top 0.2s ease;
  transition-delay: 0.2s;

  &.isExpanded {
    margin-top: 3.2rem;
    transition-delay: 0s;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 1lh;
}

.icon {
  width: 20px;
  height: 20px;
}
