@use '~/ui/styles/breakpoints.module';

.password {
  margin-bottom: 0;
}

.contact {
  margin-top: 4.8rem;
  margin-bottom: 8.8rem;

  @media (min-width: breakpoints.$md) {
    margin-top: 8rem;
    margin-bottom: 12rem;
  }

  @media (min-width: breakpoints.$lg) {
    margin-top: 12rem;
    margin-bottom: 16rem;
  }
}
