@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/typography.module';

.root {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 2.4rem 1.6rem 3.2rem 2.4rem;

  background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
    var(--theme-primary);

  @media (min-width: breakpoints.$md) {
    padding: 3.2rem 3.2rem 4rem;
  }

  @media (min-width: breakpoints.$lg) {
    padding: 4rem 4.5rem 4.5rem 4rem;
  }
}

.input {
  margin-top: 4.4rem;

  label {
    @include typography.pageTitleSmall;
  }

  input {
    @include typography.pageTitleSmall;
  }

  button > svg {
    width: 3.2rem;

    @media (min-width: breakpoints.$md) {
      width: 4rem;
    }

    @media (min-width: breakpoints.$lg) {
      width: 4.8rem;
    }
  }
}
