@import '/styles/base';
@import '../navigation.constants.module';

.secondaryNavigationWrapper {
  @include lg {
    position: sticky;
    width: auto;
  }

  pointer-events: none;

  position: fixed;
  top: 0;

  display: flex;
  flex: 1;

  width: 100%;
  height: calc(100 * var(--vh));
}

.secondaryNavigationWrapperActive {
  @include lg {
    z-index: 0;
  }

  pointer-events: all;
  z-index: $z-200;
}

.secondaryNavigation {
  @include lg {
    transform: translateX(-100%);
    flex: initial;
    // it takes 8 out of 9 grid columns 8/9 = 88.9%;
    width: 88.9%;
    max-width: 136rem;
  }

  flex: 1;
  box-sizing: border-box;
  height: 100%;
  background: var(--theme-primary);
}

.navigationInnerWrapper {
  @include lg {
    display: block;
    height: 100%;
  }

  position: relative;

  overflow: auto;
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.secondaryNavigationActive {
  z-index: 2;
}

.secondaryNavigationTop {
  @include md {
    padding: 2rem 3.6rem;
  }

  @include lg {
    position: relative;
    height: auto;
    padding: 0;
    border-bottom: 0;
  }

  position: sticky;
  z-index: 1;
  top: 0;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  padding: 0.4rem 0.8rem;

  color: var(--theme-copy);

  background-color: (var(--theme-primary));
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.topButtonWrapper {
  @include lg {
    position: absolute;
    z-index: 1;
    top: 3.2rem;
    right: 3.2rem;
  }
}

.topButton {
  @include focus(0);

  @include lg {
    padding: 1rem;
  }

  display: flex;

  padding: 1.2rem;

  color: var(--theme-copy);

  background-color: var(--theme-primary);
  border-radius: 50%;

  transition: color 0.3s ease;

  &:hover {
    cursor: pointer;
    color: rgba(var(--theme-copy-rgb), 0.65);
  }
}

.arrowButton,
.topLabel {
  @include lg {
    display: none;
  }
}

.arrowButton {
  > span {
    width: 2rem;
    height: 2rem;
  }

  .arrowIcon {
    width: 2rem;
    height: 2rem;
  }
}

.closeIcon {
  @include lg {
    width: 2.8rem;
  }

  width: 2rem;
}

.content {
  @include md {
    padding: 4.8rem;
  }

  @include lg {
    display: flex;
    flex: 1;
    justify-content: center;

    min-height: 100%;
    padding: 0;
  }

  padding: 4rem 2rem 6.4rem;
}

.contentInner {
  @include lg {
    flex-direction: row;
    justify-content: flex-end;
  }

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.searchWrapper {
  @include md {
    padding-top: 11rem;
  }

  @include lg {
    width: 75%;
    padding-top: 20rem;
  }

  width: 100%;
}

.itemsWrapper {
  @include lg {
    // it takes 3 out of 8 grid columns (.secondaryNavigation takes up 8)
    // 3/8 = 37.5%
    width: 37.5%;
    padding-top: calc(var(--nav-wordmark-height) + 2 * var(--nav-wordmark-spacing));
    padding-left: 0.6rem;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  box-sizing: border-box;
  width: 100%;
  padding-left: 0;
}

.contentRight {
  @include md {
    width: 86%;
  }

  @include lg {
    // it takes 5 out of 8 grid columns (.secondaryNavigation takes up 8)
    // 5/8 = 62.5%
    width: 62.5%;
  }

  position: relative;
}

.secondaryNavigationItem {
  @include focus;
  @include modulesTitle;

  @include lg {
    @include bodyLarge;
  }

  @include lg {
    margin-bottom: 0.8rem;
  }

  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  padding: 0;
}

.secondaryNavigationItemImage {
  @include lg {
    position: absolute;
    display: block;
    margin-right: 0;

    img {
      object-fit: cover;
    }
  }

  display: none;
  margin-right: 1.2rem;

  // stupid hack to get around the "imageContainer" class overriding these values
  &[class] {
    @include lg {
      width: 100%;
      height: 100%;
    }

    width: 5rem;
    height: 6.4rem;
  }
}

.linkImage {
  @include lg {
    display: none;
  }

  display: block;
}

.statement {
  @include lg {
    position: fixed;
    right: 4rem;
    bottom: 4rem;

    width: 48rem;
    margin-top: 0;
  }

  align-self: flex-end;

  max-width: 100%;
  margin-top: 6.4rem;

  color: var(--theme-copy);

  opacity: 1;

  transition: opacity 0.5s ease;
  transition-delay: 0.2s;
}

.statementFaded {
  @include lg {
    opacity: 0;
    transition: opacity 0.2s ease;
    transition-delay: 0s;
  }

  transition: none;
}

.noTransitions * {
  transition: none;
}

.backdropButton {
  @include lg {
    display: block;
  }

  pointer-events: none;

  position: absolute;
  inset: 0;

  display: none;

  width: 100%;

  opacity: 0;
  background-color: $color-black;

  transition: opacity 0.3s ease;

  .secondaryNavigationWrapperActive & {
    pointer-events: all;
    opacity: 0.7;
  }
}
