@use '~/ui/styles/color.module';
@use '~/ui/styles/utils.module';

.button {
  @include utils.focus;

  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 1e5px;

  transition-duration: 300ms;
  transition-property: background-color, border-color, color;

  &:disabled {
    cursor: not-allowed;
  }
}

.large {
  height: 54px;
  padding-inline: 32px;
}

.small {
  height: 38px;
  padding-inline: 24px;
}

.icon {
  width: 44px;
  height: 44px;

  // We throw an error if an icon button has more than one child
  > * {
    width: 20px;
    height: 20px;
  }
}

.primary {
  color: color.$white;
  background-color: color.$black;

  &:hover:not(:disabled) {
    background-color: color-mix(in srgb, color.$black, color.$white 25%);
  }

  &:disabled {
    color: rgba(color.$white, 0.6);
    background-color: color-mix(in srgb, color.$black, color.$white 25%);
  }
}

.secondary {
  background-color: color.$white;
}

.secondary,
.secondaryTransparent {
  color: color.$black;
  border: 2px solid rgba(color.$black, 0.15);

  &:hover:not(:disabled) {
    border-color: color.$black;
  }

  &:disabled {
    color: rgba(color.$black, 0.6);
  }
}
